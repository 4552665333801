import React, { useEffect } from 'react';
import './App.css';

const App = () => {
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);

        const intervalId = setInterval(() => {
            if (window.outerWidth - window.innerWidth > 1) {
                debugger;
            }
        }, 300);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="background">
            <div className="overlay"></div>
            <div className="content">
                <h1 className="huge-text">Pierson Hale</h1>
            </div>
        </div>
    );
};

export default App;
